<template>
  <div class="page-container">
    <header
      v-if="!hideHeader"
      class="white"
    >
      <page-header
        :title="title"
        @close="close"
      >
        <template #additionContent>
          <slot name="headerContent" />
        </template>
      </page-header>
    </header>
    <pp-container
      tag="main"
      class="py-0 d-flex flex-column page-container__main justify-start"
    >
      <slot name="content" />
    </pp-container>
    <footer
      v-if="!hideFooter"
      class="white mt-auto"
    >
      <pp-divider />
      <div
        class="d-flex my-3"
      >
        <pp-button
          type="primary"
          class="mx-auto page-continue-button"
          data-cy-id="page-continue-button"
          @click="close"
        >
          <template #text>
            <span class="px-sm-10">Close</span>
          </template>
        </pp-button>
      </div>
    </footer>
  </div>
</template>

<script>
import PageHeader from '@modal-src/components/page-header/PageHeader'
import { closeModal } from 'sdk-widget-manager'

export default {
  name: 'PageContainer',
  components: {
    'page-header': PageHeader
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      if (this.$notWidgetManagerUsage) {
        window.parent.postMessage({ eventType: 'confirmClose' }, this.$originHost)
      } else {
        closeModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex: 1 0 100vh;
  flex-direction: column;
  max-width: 100vw;
  overflow: auto;

  @include mobile() {
    // iframe container will be set to 100vh
    height: 100%;
  }

  &__main {
    flex: 1 0 200px;
    overflow: auto;
    transform: translate(0, 0);
  }
}

header {
  position: sticky;
  top: 0;
  z-index: 7;
}

</style>
