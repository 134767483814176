<template>
  <pp-app class="application-wrap">
    <router-view
      :key="$route.fullPath"
    />
  </pp-app>
</template>

<script>

export default {
  name: 'SchedulingModalApp',
  created () {
    if (this.$confirmParam) {
      this.$router.replace({ name: 'confirmPage' })
    }
  }
}
</script>
<style lang="scss">
html,
body {
  display: flex;
  flex: 1;
  max-width: 100vw;
  min-height: 100%;
}

body {
  .v-application {
    &.application-wrap {
      display: flex;
      flex: 1;
      line-height: 1.25;
      max-width: 100vw;
    }
  }
}
</style>
