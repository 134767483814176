import Vue from 'vue'
import VueRouter from 'vue-router'
import ConfirmPage from '@modal-src/views/confirm/ConfirmPage'
import getConfig from '@/utils/getConfig'

Vue.use(VueRouter)
const currentConfig = getConfig()

const routes = [
  {
    path: '/',
    name: 'mainPage'
  },
  {
    path: '/confirm',
    name: 'confirmPage',
    component: ConfirmPage
  }
]

export const nextRouteMap = {}

let routerBase = window.location.origin

if (window.self !== window.top) {
  if (currentConfig.get('originHost') === window.location.origin) {
    routerBase = currentConfig.get('originHost')
  } else {
    routerBase = process.env.BASE_URL ?? window.location.origin
  }
}

const router = new VueRouter({
  mode: 'abstract',
  base: routerBase, // use current location as base only if widget is running inside iframe
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
