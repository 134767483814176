const axios = require('axios')
const env = window.Cypress ? window.Cypress.env() : process.env

export default class MonolithApiClient {
  constructor () {
    this.client = axios.create({
      baseURL: env.VUE_APP_LEGACY_API
    })
  }

  getAppointmentInfo ({ practiceId, confirmCode }) {
    return this.client.post(`/practices/${practiceId}/appointment_patient_replied/${confirmCode}`).then(response => {
      return response.data
    })
  }
}
