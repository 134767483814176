import router from '@modal-src/router'
import store from '@modal-src/store'
import '@modal-src/plugins/pp-vue-library'
import breakpoints from '@modal-src/styles/breakpoints.scss'

import Vue from 'vue'
import App from './ModalApp.vue'

Vue.config.productionTip = false

Vue.prototype.$ppUi = {
  isMobile: window.innerWidth < breakpoints.xs
}

Vue.prototype.$vuetify = {
  rtl: false,
  lang: {
    t: (val) => val
  },
  theme: {
    dark: false
  },
  icons: {}
}
export default (config = {}) => {
  Vue.prototype.$originHost = config?.originHost ?? ''
  Vue.prototype.$notWidgetManagerUsage = config?.notWidgetManagerUsage
  Vue.prototype.$practiceId = config?.practiceId
  Vue.prototype.$confirmParam = config?.confirmParam
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
