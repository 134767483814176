<template>
  <div>
    <pp-row
      class="no-gutters d-flex "
      :class="isMobile? 'primary': ''"
    >
      <pp-col
        cols="10"
        sm="11"
        class="d-flex"
      >
        <h1
          class="text-h3 text-sm-h1 my-5  ml-5 text--darken-4 pl-5"
          :class="isMobile? 'white--text': ''"
        >
          {{ title }}
        </h1>
      </pp-col>
      <pp-col
        cols="2"
        sm="1"
        class="d-flex "
      >
        <pp-button
          type="icon"
          icon="close"
          aria-label="Dismiss modal"
          role="button"
          class="close-button ma-auto mr-4"
          :class="isMobile? 'white--text': ''"
          @click="closeModal"
        />
      </pp-col>
    </pp-row>
    <pp-divider
      :class="[
        isMobile? '': ''
      ]"
    />
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      default: 'Appointment Confirmed'
    }
  },
  data () {
    return {
      isMobile: this.$ppUi.isMobile
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    }
  }
}
</script>
