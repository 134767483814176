<template>
  <page-container
    data-testid="confirm-page-container"
    title="Appointment Confirmed"
    :hide-header="dataIsLoading"
    :hide-footer="dataIsLoading"
  >
    <template
      slot="content"
    >
      <pp-loader
        v-if="dataIsLoading"
      />
      <template v-else>
        <pp-row
          class="ma-0 d-flex flex-column"
        >
          <div class="mx-auto my-auto text-center text-h5 font-weight-regular pa-4">
            <template v-if="appointmentConfirmed">
              <div
                data-testid="appointment-confirmed"
              >
                Thanks. You are now confirmed for your appointment on<br>{{ modalInfo.dateString }}.
              </div>
              <div
                v-if="phoneExist"
                class="mt-3"
              >
                Please call us at
                <a
                  :href="`tel:${modalInfo.phoneNumber}`"
                  class="blue--text text--darken-1"
                >{{ modalInfo.phoneNumberFormatted }}</a>
                if you have any questions.
              </div>
            </template>
            <template v-else-if="appointmentHasConfirmationProblems">
              <div
                class=""
                data-testid="appointment-have-no-time"
              >
                Sorry, we couldn't confirm your appointment.
              </div>
              <div class="mt-3">
                Let our office help. Please call us at
                <a
                  :href="`tel:${modalInfo.phoneNumber}`"
                  class="blue--text text--darken-1"
                >{{ modalInfo.phoneNumberFormatted }}</a>.
              </div>
            </template>
            <template v-else>
              <div data-testid="confirm-error">
                Sorry, an error occurred.
              </div>
            </template>
          </div>
        </pp-row>
      </template>
    </template>
  </page-container>
</template>

<script>
import MonolithApiClient from '@/clients/monolithApiClient'
import PageContainer from '@modal-src/components/page-container/PageContainer'

export default {
  name: 'AppointmentConfirmPage',
  components: {
    'page-container': PageContainer
  },
  data: function () {
    return {
      isMobile: this.$ppUi.isMobile,
      dataIsLoading: true,
      modalInfo: {
        phoneNumber: '',
        phoneNumberFormatted: '',
        dateString: ''
      }

    }
  },
  computed: {
    appointmentConfirmed () { // if date exist
      return this.modalInfo.dateString
    },
    phoneExist () { // if date exist
      return this.modalInfo.phoneNumber
    },
    appointmentHasConfirmationProblems () { // if phone exist but there is no date
      return !(this.modalInfo.dateString) && this.modalInfo.phoneNumber
    }

  },
  created () {
    this.loadAppointmentData()
  },
  methods: {
    async loadAppointmentData () {
      const client = new MonolithApiClient()
      await client.getAppointmentInfo({
        practiceId: this.$practiceId,
        confirmCode: this.$confirmParam
      }).then((response) => {
        this.modalInfo = {
          phoneNumber: response?.phoneNumber ?? '',
          phoneNumberFormatted: response?.phoneNumberFormatted ?? '',
          dateString: response?.dateString ?? ''
        }
      }).catch((e) => {
        console.error(e)
      }).finally(() => {
        this.dataIsLoading = false
      })
    }
  }
}
</script>
